
import { Vue, Component, Watch } from "vue-property-decorator";
import { Parametro } from '@/core/models/compras';
import { ParametroService } from '@/core/services/compras/ParametroService';
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";

@Component
export default class ListaParametrizar extends mixins(Vue, ListPage) {
  public service = new ParametroService();
  public item = new Parametro();

  dialogCadastro: boolean = false;
  
  titulo: string = 'Parametrizar almoxarifados';
  subTitulo: string = 'Almoxarifados cadastrados no Sistema';
  
  headers: any[] = [ 
    { text: "", value: "actions", sortable: false },
    { text: "Almoxarifado Padrão", value: "almoxarifadoPadrao.nome" },
  ];

  options: any = {
    itemsPerPage: 15
  }; 

  loading: boolean = false; 
  totalLista: number = 0;
 
  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;
 
    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined,  'AlmoxarifadoPadrao').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    .finally(() => (this.loading = false));
  }
  
  Editar(item:Parametro){
    this.item = Object.assign(item);
    this.dialogCadastro = true;
  }

  Novo() {
    this.item = new Parametro();
    this.dialogCadastro = true;
  } 

  Excluir(item: Parametro) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja cancelar o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              this.Atualizar();
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result: any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
      }
    });
  }
 
}
